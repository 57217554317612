export class Note {
  id: string;
  shiftId: string;
  text: string;
  time: Date;
  isEditing: boolean = false;
  uid: string;

  constructor() {
    this.time = new Date();
  }
}
