import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Client } from 'src/app/models/client';
import { UserProfile } from 'src/app/models/user-profile';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  @Input() user: UserProfile;
  @Input() clients: Client[];

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    if(!this.user) {
      this.user = new UserProfile();
    }
  }

  onSubmit() {
    this.activeModal.close(this.user);
  }
}