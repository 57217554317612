import { Directive, forwardRef, Attribute, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import * as _ from "lodash";

@Directive({
  selector: '[NotBefore]',
  providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => NotBeforeDirective), multi: true }]
})
export class NotBeforeDirective implements Validator {
  constructor() {}

  @Input('NotBefore') compareDate: Date;

  validate(c: AbstractControl): { [key: string]: any } {
    let beforeInvalid = false;

    if(this.compareDate && c.value && _.isFinite(c.value.hour) && _.isFinite(c.value.minute)) { 
      let editVal = new Date(this.compareDate);
      editVal.setHours(c.value.hour, c.value.minute);

      beforeInvalid = this.compareDate.valueOf() - editVal.valueOf() > 0;
    }
     
    return beforeInvalid ? { beforeInvalid } : null;
  }
}