import { Injectable } from '@angular/core';
import { BaseFirestoreService } from './base-firestore.service';
import { Timesheet } from '../models/timesheet';
import { AngularFirestore } from '@angular/fire/firestore';
import { TimeEntryService } from './time-entry.service';
import { TimeEntry } from '../models/time-entry';
import * as moment from "moment";
import { UserProfileService } from './user-profile.service';

@Injectable({
  providedIn: 'root'
})
export class TimesheetService extends BaseFirestoreService<Timesheet> {
  constructor(protected firestore: AngularFirestore, private timeEntrySvc: TimeEntryService, private userService: UserProfileService) {
    super(firestore);
  }

  collection: string = "timesheets";

  async getItemFromFirestoreData(data: any): Promise<Timesheet> {
    let timesheet: Timesheet = new Timesheet();

    timesheet.uid = data.uid;
    timesheet.week = data.week;
    timesheet.approved = data.approved;
    timesheet.timeEntries = await this.timeEntrySvc.getTimeEntriesById(data.timeEntryIds);
    
    return timesheet;
  }

  mapItemToFirestoreData(item: any): any {
    let timeEntries = <TimeEntry[]> item.timeEntries;
    item.timeEntryIds = timeEntries.map(te => te.id);
    delete item.timeEntries;

    return item;
  }

  async getTimesheetForUserWeek(weekStart: Date): Promise<Timesheet> {
    let weekKey: string = moment(weekStart).format('YYYY-MM-DD');
    let uid = (await this.userService.getCurrentUser()).id;
    let timesheets = await this.Query(collection => collection.where('uid', '==', uid).where('week', '==', weekKey));

    return timesheets.length ? timesheets[0] : null;
  }
}
