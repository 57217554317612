import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import * as moment from "moment";
import * as _ from "lodash";

import { TimeEntry } from '../models/time-entry';
import { BaseFirestoreService } from './base-firestore.service';
import { DateUtilities } from '../shared/date-utilities';
import { TimeCalcsService } from './time-calcs.service';

@Injectable({
  providedIn: 'root'
})
export class TimeEntryService extends BaseFirestoreService<TimeEntry> {
  collection: string = "time-entries";

  constructor(protected firestore: AngularFirestore, private authSvc: AngularFireAuth, private timeCalcs: TimeCalcsService) {
    super(firestore);
  }

  async getItemFromFirestoreData(docData: any): Promise<TimeEntry> {
    let timeEntry: TimeEntry = new TimeEntry();
    timeEntry.shiftId = docData.shiftId;
    timeEntry.locked = docData.locked || false;
    timeEntry.clientId = docData.clientId;
    timeEntry.uid = docData.uid;
    timeEntry.clockInTimestamp = DateUtilities.getDateFromNullableTimestamp(docData.clockInTimestamp);
    timeEntry.clockInOverride = DateUtilities.getDateFromNullableTimestamp(docData.clockInOverride);
    timeEntry.clockInOverrideReason = docData.clockInOverrideReason;
    timeEntry.clockInPosition = docData.clockInPosition;
    timeEntry.clockOutTimestamp = DateUtilities.getDateFromNullableTimestamp(docData.clockOutTimestamp);
    timeEntry.clockOutOverride = DateUtilities.getDateFromNullableTimestamp(docData.clockOutOverride);
    timeEntry.clockOutOverrideReason = docData.clockOutOverrideReason;
    timeEntry.clockOutPosition = docData.clockOutPosition;
    
    return timeEntry;
  }

  mapItemToFirestoreData(item: any): any {
    item.clockInTimestamp = DateUtilities.getTimestampFromNullableDate(item.clockInTimestamp);
    item.clockInOverride = DateUtilities.getTimestampFromNullableDate(item.clockInOverride);
    item.clockOutTimestamp = DateUtilities.getTimestampFromNullableDate(item.clockOutTimestamp);
    item.clockOutOverride = DateUtilities.getTimestampFromNullableDate(item.clockOutOverride);
    item.clockInPosition = this.getPositionPlainObject(item.clockInPosition);
    item.clockOutPosition = this.getPositionPlainObject(item.clockOutPosition);

    return item;
  }

  async getTimeEntriesForRange(start: Date, end: Date): Promise<TimeEntry[]> {
    let firestoreResult = await this.Query((collectionRef) => {
      let qStart = moment(start).add(-2, 'day');
      let qEnd = moment(end).add(2, 'day');

      return collectionRef
        .where("uid", '==', this.authSvc.auth.currentUser.uid)
        .where("clockInTimestamp", ">=", qStart.toDate())
        .where("clockInTimestamp", "<=", qEnd.toDate());
    });

    let entries = _.filter(firestoreResult, (te) => {
      return te.clockIn <= end && te.clockOut > start;
    });

    return entries;
  }

  async getTimeEntriesForWeek(date: Date): Promise<TimeEntry[]> {
    let start: Date = this.timeCalcs.getWeekStartingSaturday(date);
    let end: Date = moment(start).add(1, 'week').add(-1, 'millisecond').toDate();

    return this.getTimeEntriesForRange(start, end);
  }

  async getTimeEntriesForShift(shiftId: string): Promise<TimeEntry[]> {
    let firestoreResult = await this.Query((collectionRef) => {
      return collectionRef
        .where("uid", '==', this.authSvc.auth.currentUser.uid)
        .where("shiftId", '==', shiftId);
    });

    return firestoreResult;
  }

  async getTimeEntriesById(timeEntryIds: string[]): Promise<TimeEntry[]> {
    let timeEntries = await Promise.all(timeEntryIds.map(id => this.Get(id)));

    return timeEntries;
  }

  private getPositionPlainObject(position: Position): any {
    if(!position) return null;

    let val: any = {};

    val.timestamp = position.timestamp;
    let coords = position.coords;
    val.coords = {
      accuracy: coords.accuracy,
      altitude: coords.altitude,
      altitudeAccuracy: coords.altitudeAccuracy,
      heading: coords.heading,
      latitude: coords.latitude,
      longitude: coords.longitude,
      speed: coords.speed,
    };

    return val;
  }
}
