import { Directive, forwardRef, Attribute } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import * as _ from "lodash";

@Directive({
  selector: '[ValidRating][formControlName],[ValidRating][formControl],[ValidRating][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => ValidRatingDirective), multi: true }]
})
export class ValidRatingDirective implements Validator {
  constructor( @Attribute('validateEqual') public validateEqual: string) {}

  validate(c: AbstractControl): { [key: string]: any } {
    if (!_.isFinite(c.value) || c.value < 1) {
      return { ratingValid: false };
    } 
    return null;
  }
}