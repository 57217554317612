import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentData } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { firestore } from 'firebase';
import * as _ from 'lodash';

import { LocalStorageService } from './local-storage.service';
import { Shift } from '../models/shift';
import { Meal } from '../models/meal';
import { Note } from '../models/note';

@Injectable({
  providedIn: 'root'
})
export class ShiftService {
  userId: string;

  constructor(private firestore: AngularFirestore, private authSvc: AngularFireAuth, private storageSvc: LocalStorageService) {
    this.saveShift = this.saveShift.bind(this);
    this.userId = this.authSvc.auth.currentUser.uid;
  }

  // shift area

  async getShift(eventId: string, parentEventId: string): Promise<Shift> {
    let self: ShiftService = this;
    let shiftClientId = this.storageSvc.getShiftClientId(parentEventId);
    let uid = this.userId;
    let shiftQuery = this.firestore.collection("shifts").ref
      .where("uid", '==', uid)
      .where("eventId", '==', eventId);
      // TODO: other filters

    return shiftQuery.get().then(function(querySnapshot) {
      let shift: Shift = new Shift();
      shift.eventId = eventId;
      shift.parentEventId = parentEventId;
      shift.uid = uid;
      shift.clientId = shiftClientId;

      if(querySnapshot.docs && querySnapshot.docs.length > 0) {
        let snapshot = querySnapshot.docs[0];
        let snapshotData = snapshot.data();

        shift.shiftId = snapshot.id;
        shift.clientId = snapshotData.clientId;
        shift.appetiteLevel = snapshotData.appetiteLevel || null;
        shift.energyLevel = snapshotData.energyLevel || null;
        shift.painLevel = snapshotData.painLevel || null;
        shift.uid = snapshotData.uid;
      } else {
        return self.saveShift(shift);
      }
      
      return shift;
    });
  }

  private primeShift(shift: Shift): any {
    let strippedShift: any = {...shift};
    delete strippedShift.meals;
    delete strippedShift.notes;
    delete strippedShift.timeEntries;

    return strippedShift;
  }

  private async saveShift(shift: Shift): Promise<Shift> {
    let strippedShift: any = this.primeShift(shift);

    return this.firestore.collection("shifts")
      .add(strippedShift)
      .then(res => {
        shift.shiftId = res.id;
        return shift;
      });
  }

  async updateShift(shift: Shift): Promise<void> {
    let strippedShift: any = this.primeShift(shift);
    this.storageSvc.setShiftClientId(shift.parentEventId, shift.clientId);

    return this.firestore.collection("shifts").doc(shift.shiftId).update(strippedShift);
  }

  // meals area
  async getMeals(shiftId: string): Promise<Array<Meal>> {
    let uid = this.userId;
    let mealQuery = this.firestore.collection("meals").ref
      .where("uid", '==', uid)
      .where("shiftId", '==', shiftId);

    return mealQuery.get().then(function(querySnapshot) {

      if(querySnapshot.docs && querySnapshot.docs.length > 0) {
        let meals = _.map(querySnapshot.docs, (doc) => {
          let meal: Meal = new Meal();
          let data: DocumentData = doc.data();
          meal.id = doc.id;
          meal.shiftId = shiftId;
          meal.food = data.food;
          meal.meal = data.meal;
          meal.percent = data.percent;
          meal.time = data.timestamp.toDate();
          meal.uid = uid;

          return meal;
        });
        
        return meals;
      }
      
      return [];
    });
  }

  private primeMeal(meal: Meal): any {
    let strippedMeal: any = {...meal};
    delete strippedMeal.time;
    strippedMeal.timestamp = firestore.Timestamp.fromDate(meal.time);

    return strippedMeal;
  }

  async saveMeal(shiftId: string, meal: Meal): Promise<Meal> {
    meal.shiftId = shiftId;
    meal.uid = this.userId;
    let strippedMeal: any = this.primeMeal(meal);

    return this.firestore.collection("meals")
      .add(strippedMeal)
      .then(res => {
        meal.id = res.id;
        return meal;
      });
  }

  async updateMeal(meal: Meal): Promise<void> {
    let strippedMeal: any = this.primeMeal(meal);

    return this.firestore.collection("meals").doc(meal.id).update(strippedMeal);
  }

  // notes area
  async getNotes(shiftId: string): Promise<Array<Note>> {
    let uid = this.userId;
    let noteQuery = this.firestore.collection("notes").ref
      .where("uid", '==', uid)
      .where("shiftId", '==', shiftId)
      .orderBy("timestamp");

    return noteQuery.get().then(function(querySnapshot) {

      if(querySnapshot.docs && querySnapshot.docs.length > 0) {
        let notes = _.map(querySnapshot.docs, (doc) => {
          let note: Note = new Note();
          let data: DocumentData = doc.data();
          note.id = doc.id;
          note.shiftId = shiftId;
          note.text = data.text;
          note.time = data.timestamp.toDate();
          note.uid = uid;

          return note;
        });
        
        return notes;
      }
      
      return [];
    });
  }

  private primeNote(note: Note): any {
    let strippedNote: any = {...note};
    delete strippedNote.time;
    delete strippedNote.isEditing;
    strippedNote.timestamp = firestore.Timestamp.fromDate(note.time);

    return strippedNote;
  }

  async saveNote(shiftId: string, note: Note): Promise<Note> {
    note.shiftId = shiftId;
    note.uid = this.userId;
    let strippedNote: any = this.primeNote(note);

    return this.firestore.collection("notes")
      .add(strippedNote)
      .then(res => {
        note.id = res.id;
        return note;
      });
  }

  async updateNote(note: Note): Promise<void> {
    let strippedNote: any = this.primeNote(note);

    return this.firestore.collection("notes").doc(note.id).update(strippedNote);
  }

}
