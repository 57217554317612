import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TimeCalcsService } from 'src/app/services/time-calcs.service';

@Component({
  selector: 'app-clock-in',
  templateUrl: './clock-in.component.html',
  styleUrls: ['./clock-in.component.scss']
})
export class ClockInComponent implements OnInit {
  @Input() previousClockOut: Date;
  @Input() shiftEventStart: Date;
  override: boolean = false;
  clockTime: any = { hour: 0, minute: 0 };
  overrideReason: string;

  constructor(public activeModal: NgbActiveModal, private timeSvc: TimeCalcsService) {
  }

  ngOnInit() {
    let date = new Date();
    this.clockTime.hour = date.getHours();
    this.clockTime.minute = date.getMinutes();
  }

  onSubmit() {
    let response: any = { override: this.override };
    if(response.override) {
      if(this.previousClockOut) {
        response.time = this.timeSvc.getNextTime(this.previousClockOut, this.clockTime.hour, this.clockTime.minute);
      } else {
        response.time = this.timeSvc.getClosestTime(this.shiftEventStart, this.clockTime.hour, this.clockTime.minute);
      }
      response.reason = this.overrideReason;
    }
    
    this.activeModal.close(response);
  }

}
