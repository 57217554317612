import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router, NavigationStart, Event } from '@angular/router';
import { LocalStorageService } from './services/local-storage.service';
import { AppService } from './services/app.service';
import { UserProfileService } from './services/user-profile.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  collapsed: boolean = true;
  title: string = 'delphina-admin';
  mainClass: string;
  isAdmin: boolean;

  constructor(private router: Router, public auth: AngularFireAuth, private storageSvc: LocalStorageService, 
              private appSvc: AppService, public profileSvc: UserProfileService) {
    this.mainClass = this.appSvc.isMobile() ? 'mobile' : '';

    this.router.events.subscribe((value: Event) => {
      if(value instanceof NavigationStart) {
        this.collapsed = true;
      }
    });
  }

  ngOnInit(): void {
    this.isAdmin = false;
    
    this.profileSvc.isCurrentUserAdmin().subscribe(isAdmin => {
      this.isAdmin = isAdmin;
    });
  }

  private toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
  }

  private logout() {
     this.auth.auth.signOut();
     this.storageSvc.setGoogleAccessToken(null);
     this.router.navigateByUrl('/home');
  }

  private login() {
     this.router.navigate(['/login']);
  }
}
