import { BaseModel } from './base-model';

export class UserProfile extends BaseModel {
  uid: string;
  providerId: string;
  email: string;
  givenName: string;
  familyName: string;
  name: string;
  imageUrl: string;
  isAdmin: boolean;
  clients: string[];
  lastLogin: Date;

  copy(target: UserProfile = new UserProfile()): UserProfile {
    target.id = this.id;

    target.uid = this.id;
    target.providerId = this.providerId;
    target.email = this.email;
    target.givenName = this.givenName;
    target.familyName = this.familyName;
    target.name = this.name;
    target.imageUrl = this.imageUrl;
    target.isAdmin = this.isAdmin;
    target.clients = this.clients;
    target.lastLogin = this.lastLogin;

    return target;
  }
}
  