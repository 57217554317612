import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, from } from 'rxjs';
import { take, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GeolocationService implements CanActivate  {
  constructor(private router: Router) { }

  getGeolocation(): Promise<Position> {
    return new Promise((res, rej) => {
      navigator.geolocation.getCurrentPosition(position => {
        return res(position);
      }, error => {
        return res();
      });
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):  Observable<boolean> {
    let statusPromise: Promise<boolean>;

    if((navigator as any).permissions) {
      statusPromise = (navigator as any).permissions.query({name: 'geolocation'})
        .then(permissionStatus => {
          return permissionStatus.state === 'granted';
        });
    } else {
      statusPromise = this.getGeolocation().then(position => {
        return !!position;
      });
    }

    return from(statusPromise)
      .pipe(tap(authenticated => {
        if (!authenticated) {
          this.router.navigate(['/geolocation'], {
            queryParams: {
              return: state.url
            }
          });
        }
    }));
  }
}
