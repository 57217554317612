import { TimeEntry } from './time-entry';
import { Client } from './client';

export class CalendarEvent {
  id: string;
  parentId: string;
  title: string;
  start: Date;
  end: Date;
  url: string;
  location: string;
  description: string;
  allDay: boolean;
  clientName: string; // TODO: persist this back to the shift event?
  duration: number;
  locked: boolean;

  constructor() { }

  copyGCalEvent(entry: any): CalendarEvent {
    var url = '/#/shifts/' + entry.id;

    this.id = entry.id;
    this.parentId = entry.recurringEventId || entry.id;
    this.title = entry.summary;
    this.start = new Date(entry.start.dateTime || entry.start.date); // try time. will fall back to all-day
    this.end = new Date(entry.end.dateTime || entry.end.date); // same
    this.url = url;
    this.location = entry.location;
    this.description = entry.description;
    this.allDay = false;
    this.locked = false;
    this.duration = null;

    return this;
  }

  copyTimeEntry(entry: TimeEntry, client: Client): CalendarEvent {

    this.id = entry.id;
    this.start = entry.clockIn;
    this.end = entry.clockOut;
    this.title = client.clientName;
    this.allDay = false;
    this.duration = entry.duration;
    this.locked = entry.locked;

    return this;
  }
}
