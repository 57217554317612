import { Injectable } from '@angular/core';
import * as moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class TimeCalcsService {

  constructor() { }  

  getNextTime(timestamp: Date, hour: number, minute: number): Date {
    let tsMoment = moment(timestamp);
    let newMoment = moment(tsMoment);

    newMoment.hour(hour);
    newMoment.minute(minute);

    if(newMoment.isBefore(tsMoment)) newMoment = newMoment.add(1, 'd');

    return newMoment.toDate();
  }

  getClosestTime(timestamp: Date, hour: number, minute: number): Date {
    let tsMoment = moment(timestamp);
    let newMoment = moment(tsMoment);

    newMoment.hour(hour);
    newMoment.minute(minute);

    let diff = Math.abs(tsMoment.diff(newMoment));

    if(diff > Math.abs(tsMoment.diff(newMoment.add(1, 'd')))) {
      newMoment = newMoment.add(1, 'd');
      diff = Math.abs(tsMoment.diff(newMoment));
    }

    if(diff > Math.abs(tsMoment.diff(newMoment.add(-1, 'd')))) {
      newMoment = newMoment.add(-1, 'd');
      diff = Math.abs(tsMoment.diff(newMoment));
    }

    return newMoment.toDate();
  }

  getLastSaturday(): Date {
    let weekAgo = moment().add(-1, 'week');
    return this.getWeekStartingSaturday(weekAgo.toDate());
  }

  getWeekStartingSaturday(timestamp: Date): Date {
    let mValue = moment(timestamp);

    let saturday = mValue.day(6);
    let saturdayMidnight = saturday.startOf('day');

    return saturdayMidnight.toDate();
  }

  addWeeks(date: Date, numWeeks: number): Date {
    return moment(date).add(numWeeks, 'week').toDate();
  }
}
