import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(private firestore: AngularFirestore) { }

  createRecord() {
    return new Promise<any>((resolve, reject) => {
      this.firestore.collection("widgets")
        .add({fun: 'happy'})
        .then(res => resolve(res), err => reject(err));
    });
  }
}
