import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Client } from 'src/app/models/client';

@Component({
  selector: 'app-client-edit',
  templateUrl: './client-edit.component.html',
  styleUrls: ['./client-edit.component.scss']
})
export class ClientEditComponent implements OnInit {
  @Input() client: Client;
  action: string = "Edit";

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    if(!this.client) {
      this.client = new Client();
      this.action = "Add";
    }
  }

  onSubmit() {    
    this.activeModal.close(this.client);
  }

}
