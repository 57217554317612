import { Component, OnInit, Input } from '@angular/core';
import * as moment from "moment";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// import { TimeCalcsService } from 'src/app/services/time-calcs.service';
import { TimeCalcsService } from '../../services/time-calcs.service';

@Component({
  selector: 'app-submit-time',
  templateUrl: './submit-time.component.html',
  styleUrls: ['./submit-time.component.scss']
})
export class SubmitTimeComponent implements OnInit {
  @Input() weekDuration: number;
  @Input() weekStart: Date;
  start: Date;
  end: Date;

  constructor(public activeModal: NgbActiveModal, private timeCalcs: TimeCalcsService) { }

  ngOnInit() {    
    this.start = this.timeCalcs.getWeekStartingSaturday(this.weekStart);
    this.end = moment(this.start).add(1, 'week').add(-1, 'millisecond').toDate();
  }

  onSubmit() {    
    this.activeModal.close(true);
  }

}
