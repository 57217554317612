import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserProfile } from '../models/user-profile';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BaseFirestoreService } from './base-firestore.service';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService extends BaseFirestoreService<UserProfile> {
  constructor(protected firestore: AngularFirestore, private auth: AngularFireAuth) {
    super(firestore);
  }

  collection: string = "user-profiles";

  async getItemFromFirestoreData(data: any): Promise<UserProfile> {
    let profile: UserProfile = new UserProfile();
    
    profile.uid = data.id;
    profile.providerId = data.providerId;
    profile.email = data.email;
    profile.givenName = data.givenName;
    profile.familyName = data.familyName;
    profile.name = data.name;
    profile.imageUrl = data.imageUrl;
    profile.isAdmin = data.isAdmin;
    profile.clients = data.clients || [];
    profile.lastLogin = data.lastLogin;
    
    return profile;
  }

  async getCurrentUser(): Promise<UserProfile> {
    if(this.auth.auth && this.auth.auth.currentUser && this.auth.auth.currentUser.uid) {
      return await this.Get(this.auth.auth.currentUser.uid);
    }
    return null;
  }

  isCurrentUserAdmin() : Observable<boolean> {
    return from(this.auth.authState)
      .pipe(map(state => !!state))
      .pipe(switchMap(async authenticated => {
        if (!authenticated) return false;
        let profile = await this.getCurrentUser();
    
        return profile ? profile.isAdmin : false;
      }));
  }

   async createOrUpdateUserProfile(profile: UserProfile): Promise<UserProfile> {        
    profile.isAdmin = false;

    let storeProfile = await this.Get(profile.uid);

    if(storeProfile) {
       profile.id = storeProfile.id;
       profile.isAdmin = storeProfile.isAdmin;

       // update the record in the database
       return this.Update(profile);
    } else {
       return this.Add(profile);
    }
 }
}
