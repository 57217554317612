import { Component, OnInit, Input } from '@angular/core';
import { TimeEntry } from 'src/app/models/time-entry';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientService } from 'src/app/services/client.service';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { Client } from 'src/app/models/client';

@Component({
  selector: 'app-edit-time-entry',
  templateUrl: './edit-time-entry.component.html',
  styleUrls: ['./edit-time-entry.component.scss']
})
export class EditTimeEntryComponent implements OnInit {
  @Input() timeEntry: TimeEntry;
  entryModel: any = {
    clockInDate: { year: 0, month: 0, day: 0 },
    clockInTime: { hour: 0, minute: 0 },
    clockOutDate: { year: 0, month: 0, day: 0 },
    clockOutTime: { hour: 0, minute: 0 },
    clientId: '',
    overrideReason: ''
  };
  availableClients: Client[];

  constructor(public activeModal: NgbActiveModal, private clientSvc: ClientService, private profileSvc: UserProfileService) { }

  async ngOnInit() {
    let user = await this.profileSvc.getCurrentUser();
    this.availableClients = await this.clientSvc.GetClientsByIds(user.clients);

    let clockInTimestamp = this.timeEntry.clockIn;
    let clockOutTimestamp = this.timeEntry.clockOut;

    this.entryModel.clockInDate = { year: clockInTimestamp.getFullYear(), month: clockInTimestamp.getMonth()+1, day: clockInTimestamp.getDate() };
    this.entryModel.clockInTime = { hour: clockInTimestamp.getHours(), minute: clockInTimestamp.getMinutes() };
    this.entryModel.clockOutDate = { year: clockOutTimestamp.getFullYear(), month: clockOutTimestamp.getMonth()+1, day: clockOutTimestamp.getDate() };
    this.entryModel.clockOutTime = { hour: clockOutTimestamp.getHours(), minute: clockOutTimestamp.getMinutes() };
    this.entryModel.overrideReason = this.timeEntry.clockInOverrideReason === this.timeEntry.clockOutOverrideReason ? this.timeEntry.clockInOverrideReason : '';

    this.entryModel.clientId = this.timeEntry.clientId;
  }

  onSubmit() {
    this.timeEntry.clockInOverrideReason = this.timeEntry.clockOutOverrideReason = this.entryModel.overrideReason;

    this.timeEntry.clockInOverride = this.getDateFromModel(this.entryModel.clockInDate, this.entryModel.clockInTime);
    this.timeEntry.clockOutOverride = this.getDateFromModel(this.entryModel.clockOutDate, this.entryModel.clockOutTime);

    this.timeEntry.clientId = this.entryModel.clientId;
    
    this.activeModal.close(this.timeEntry);
  }

  private getDateFromModel(dateModel: any, timeModel: any): Date {
    return new Date(dateModel.year, dateModel.month-1, dateModel.day, timeModel.hour, timeModel.minute);
  }

}
