import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MomentModule } from "ngx-moment";
import { NgxSpinnerModule } from "ngx-spinner";
import { FullCalendarModule } from "@fullcalendar/angular";
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlus, faEdit, faSave } from '@fortawesome/free-solid-svg-icons';
import { NgSelectModule } from '@ng-select/ng-select';
import { GoogleApiConfig, GoogleApiService, GoogleApiModule, GoogleAuthService, NgGapiClientConfig, NG_GAPI_CONFIG } from "ng-gapi";

import { environment } from '../environments/environment';
import { DataService } from './services/data.service';
import { AppComponent } from './app.component';
import { DataComponent } from './data/data.component';
import { LoginComponent } from './admin/login/login.component';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from "./app-routing.module";
import { HiddenComponent } from './hidden/hidden.component';
import { AuthGuard } from './services/auth.service';
import { ShiftCalendarComponent } from './shift-calendar/shift-calendar.component';
import { ShiftComponent } from './shift/shift.component';
import { ApiGuardService } from './services/api-guard.service';
import { MealModalComponent } from './shift/meal-modal/meal-modal.component';
import { ValidRatingDirective } from './directives/valid-rating.directive';
import { DurationPipe } from './pipes/duration.pipe';
import { GeolocationComponent } from './admin/geolocation/geolocation.component';
import { ClientNameComponent } from './shift/client-name/client-name.component';
import { ClockInComponent } from './shift/clock-in/clock-in.component';
import { ClockOutComponent } from './shift/clock-out/clock-out.component';
import { NotFutureDirective } from './directives/not-future.directive';
import { NotBeforeDirective } from './directives/not-before.directive';
import { UserAdminComponent } from './admin/user-admin/user-admin.component';
import { AdminService } from './services/admin.service';
import { ShiftAddComponent } from './shift-calendar/shift-add/shift-add.component';
import { ClientAdminComponent } from './admin/client-admin/client-admin.component';
import { ClientEditComponent } from './admin/client-admin/client-edit/client-edit.component';
import { UserEditComponent } from './admin/user-admin/user-edit/user-edit.component';
import { TimesheetsComponent } from './timesheets/timesheets.component';
import { EditTimeEntryComponent } from './timesheets/edit-time-entry/edit-time-entry.component';
import { SubmitTimeComponent } from './timesheets/submit-time/submit-time.component';

let gapiClientConfig: NgGapiClientConfig = {
  client_id: environment.gapi.clientId,
  hosted_domain: environment.gapi.hostedDomain,
  discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
  scope: 'https://www.googleapis.com/auth/calendar' // space separated string of scopes
};

@NgModule({
  declarations: [
    AppComponent,
    DataComponent,
    LoginComponent,
    HomeComponent,
    HiddenComponent,
    ShiftCalendarComponent,
    ShiftComponent,
    MealModalComponent,
    ValidRatingDirective,
    DurationPipe,
    GeolocationComponent,
    ClientNameComponent,
    ClockInComponent,
    ClockOutComponent,
    NotFutureDirective,
    NotBeforeDirective,
    UserAdminComponent,
    ShiftAddComponent,
    ClientAdminComponent,
    ClientEditComponent,
    UserEditComponent,
    TimesheetsComponent,
    EditTimeEntryComponent,
    SubmitTimeComponent
  ],
  entryComponents: [
    MealModalComponent,
    ClientNameComponent,
    ClockInComponent,
    ClockOutComponent,
    ShiftAddComponent,
    ClientEditComponent,
    UserEditComponent,
    EditTimeEntryComponent,
    SubmitTimeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    BrowserAnimationsModule,
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        'm': 59
      }
    }),
    NgxSpinnerModule,
    FullCalendarModule,
    FormsModule,
    FontAwesomeModule,
    GoogleApiModule.forRoot({
      provide: NG_GAPI_CONFIG,
      useValue: gapiClientConfig
    }),
    NgSelectModule
  ],
  providers: [ DataService, AuthGuard, ApiGuardService, AdminService ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    // Add icons to the library for convenient access in other components
    library.add(faPlus);
    library.add(faEdit);
    library.add(faSave);
  }
}
