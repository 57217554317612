import { CanActivate, Router, ActivatedRoute, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { map, tap, take } from 'rxjs/operators';
import { GapiService } from './google-api.service';

@Injectable()
export class ApiGuardService implements CanActivate  {
  constructor(private auth: AngularFireAuth, private router: Router, private route: ActivatedRoute, private apiSvc: GapiService) { }

  canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<boolean> {
    var tokenPromise = this.apiSvc.getCurrentGoogleAccessToken();

    return from(tokenPromise)
      .pipe(take(1))
      .pipe(map(token => !!token))
      .pipe(tap(authenticated => {
        if (!authenticated) {
          this.router.navigate(['/login'], {
            queryParams: {
              return: routerState.url
            }
          });
        }
    }));
  }

}
