import { Component, OnInit } from '@angular/core';
import { GeolocationService } from 'src/app/services/geolocation.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-geolocation',
  templateUrl: './geolocation.component.html',
  styleUrls: ['./geolocation.component.scss']
})
export class GeolocationComponent implements OnInit {
  return: string = '';
  error: string;

  constructor(private geoSvc: GeolocationService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    // Get the query params
    this.route.queryParams
       .subscribe(params => this.return = params['return'] || '/home');
  }

  requestGeolocation() {
    this.geoSvc.getGeolocation().then(position => {
      if(position) {        
        this.router.navigateByUrl(this.return);
      } else {
        this.error = "Location permission not granted!";
      }
    });
  }

}
