import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import { AngularFireAuth } from '@angular/fire/auth';
import { GapiService } from '../services/google-api.service';
import { AppService } from '../services/app.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ShiftAddComponent } from './shift-add/shift-add.component';
import { FullCalendarComponent } from '@fullcalendar/angular';


@Component({
  selector: 'app-shift-calendar',
  templateUrl: './shift-calendar.component.html',
  styleUrls: ['./shift-calendar.component.scss']
})
export class ShiftCalendarComponent implements OnInit {
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  calendarPlugins = [ timeGridPlugin, dayGridPlugin ];
  firstDay: number = 6;
  defaultView: string;
  header: any;
  customButtons: any;

  constructor(private apiSvc: GapiService, private appSvc: AppService, private modalService: NgbModal) {
    // to resolve issues with overridden "this"
    this.getEvents = this.getEvents.bind(this);
    this.addClicked = this.addClicked.bind(this);

    let isMobile = this.appSvc.isMobile();
    this.defaultView = isMobile ? 'dayGridDay' : 'timeGridWeek';
    this.customButtons = {
      addShiftButton: {
        text: 'add',
        click: this.addClicked
      }
    };

    let mobileHeader = { 
      left: 'prev,next', 
      center: 'title', 
      right: 'dayGridDay,dayGridWeek,addShiftButton' 
    };

    let standardHeader = { 
      left: 'prev,next today', 
      center: 'title', 
      right: 'timeGridWeek,timeGridDay,addShiftButton' 
    };

    this.header = isMobile ? mobileHeader : standardHeader;
  }

  ngOnInit() { }

  async getEvents(params, callback) {
    let events = await this.apiSvc.getCalendarEvents(params);
    console.log(events);
    callback(events);
  }

  async addClicked() {
    const modalRef: NgbModalRef = this.modalService.open(ShiftAddComponent);

    let result = await modalRef.result;
    console.log('add modal result: ', result);
    await this.apiSvc.addCalendarEvent(result.start, result.end);
    let calApi = this.calendarComponent.getApi();
    calApi.refetchEvents();
  }
}
