import { Component, OnInit, ViewChild } from '@angular/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { TimeEntryService } from '../services/time-entry.service';
import { CalendarEvent } from '../models/calendar-event';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { EditTimeEntryComponent } from './edit-time-entry/edit-time-entry.component';
import { TimeEntry } from '../models/time-entry';
import { TimeCalcsService } from '../services/time-calcs.service';
import * as moment from "moment";
import { DateUtilities } from '../shared/date-utilities';
import { ClientService } from '../services/client.service';
import { SubmitTimeComponent } from './submit-time/submit-time.component';
import { Timesheet } from '../models/timesheet';
import { UserProfileService } from '../services/user-profile.service';
import { TimesheetService } from '../services/timesheet.service';

@Component({
  selector: 'app-timesheets',
  templateUrl: './timesheets.component.html',
  styleUrls: ['./timesheets.component.scss']
})
export class TimesheetsComponent implements OnInit {
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  calendarPlugins = [ timeGridPlugin, dayGridPlugin ];
  weekStart: Date;
  firstDay: number = 6;
  customButtons: any;
  header: any;
  buttonIcons: any;
  weekDuration: number;
  timeEntryEvents: CalendarEvent[];
  timesheet: Timesheet;
  loadComplete: boolean = false;

  constructor(private timeEntrySvc: TimeEntryService, private modalService: NgbModal, private timeCalcs: TimeCalcsService,
              private clientSvc: ClientService, private userProfileSvc: UserProfileService, private timesheetSvc: TimesheetService) {
    // to resolve issues with overridden "this"
    this.prev = this.prev.bind(this);
    this.next = this.next.bind(this);
    this.add = this.add.bind(this);
    this.submit = this.submit.bind(this);

    
    this.customButtons = {
      prevButton: {
        text: 'prev',
        click: this.prev
      },
      nextButton: {
        text: 'next',
        click: this.next
      },
      addButton: {
        text: 'add',
        click: this.add
      }
    };

    this.header = { 
      left: 'title', 
      right: 'prevButton,nextButton addButton' 
    };

    this.buttonIcons = {
      prevButton: 'chevron-left',
      nextButton: 'chevron-right',
      addButton: 'plus-square'
    };

  }

  async ngOnInit() {
    this.weekStart = this.timeCalcs.getLastSaturday();
    this.loadWeek();
  }

  prev() {
    this.weekStart = this.timeCalcs.addWeeks(this.weekStart, -1);
    this.loadWeek();
  }

  next() {
    this.weekStart = this.timeCalcs.addWeeks(this.weekStart, 1);
    this.loadWeek();
  }

  async add() {
    if(this.timesheet) return;

    const modalRef: NgbModalRef = this.modalService.open(EditTimeEntryComponent);
    
    let newEntry: TimeEntry = new TimeEntry();
    let user = await this.userProfileSvc.getCurrentUser();
    newEntry.uid = user.id;
    newEntry.clockInTimestamp = newEntry.clockOutTimestamp = new Date();
    newEntry.shiftId = null;

    modalRef.componentInstance.timeEntry = newEntry;

    let result = <TimeEntry> await modalRef.result;
    await this.timeEntrySvc.Add(result);

    this.loadWeek();
  }

  get hasPendingTimesheet(): boolean {
    return this.loadComplete && !!this.timesheet && !this.timesheet.approved;
  }

  get hasApprovedTimesheet(): boolean {
    return  this.loadComplete && !!this.timesheet && this.timesheet.approved;
  }

  get hasNoTimesheet(): boolean {
    return this.loadComplete && !this.timesheet;
  }

  async loadWeek() {
    this.timesheet = await this.timesheetSvc.getTimesheetForUserWeek(this.weekStart);

    let entries = await this.timeEntrySvc.getTimeEntriesForWeek(this.weekStart);
    let calEvents = await Promise.all(entries.map(async e => {
      let client = await this.clientSvc.Get(e.clientId);
      return new CalendarEvent().copyTimeEntry(e, client);
    }));

    let result = this.getDaySummaryEvents(entries);

    this.weekDuration = result[0];

    console.log(DateUtilities.formatMillisecondsAsDuration(this.weekDuration));

    this.timeEntryEvents = calEvents.concat(result[1]);

    let calApi = this.calendarComponent.getApi();
    calApi.gotoDate(this.weekStart);

    this.loadComplete = true;
  }

  async eventClick(info) {
    if(this.timesheet || info.event.allDay || info.event.extendedProps.locked) return;

    let timeEntry = await this.timeEntrySvc.Get(info.event.id);
    const modalRef: NgbModalRef = this.modalService.open(EditTimeEntryComponent);
    
    let copyEntry: TimeEntry = timeEntry.copy();
    modalRef.componentInstance.timeEntry = copyEntry;

    let result = <TimeEntry> await modalRef.result;
    await this.timeEntrySvc.Update(result);

    this.loadWeek();
  }

  async submit() {
    if(this.timesheet) return;

    const modalRef: NgbModalRef = this.modalService.open(SubmitTimeComponent);
    modalRef.componentInstance.weekDuration = this.weekDuration;
    modalRef.componentInstance.weekStart = this.weekStart;
    let result = <boolean> await modalRef.result;
    
    if(result) {
      await this.splitTimeEntriesAtWeekBoundaries();
      let entries = await this.timeEntrySvc.getTimeEntriesForWeek(this.weekStart);
      let timesheet = new Timesheet();
      let user = await this.userProfileSvc.getCurrentUser();
      timesheet.uid = user.id;
      timesheet.week = moment(this.weekStart).format('YYYY-MM-DD');
      entries = await Promise.all(entries.map(te => {
        te.locked = true;
        return this.timeEntrySvc.Update(te);
      }));
      timesheet.timeEntries = entries;

      this.timesheetSvc.Add(timesheet);

      this.loadWeek();
    }
  }

  getDaySummaryEvents(timeEntries: TimeEntry[]): [number, CalendarEvent[]] {
    let weekStart = this.weekStart;
    let summaries: CalendarEvent[] = [];
    let weekHoursSum: number = 0;

    for (let i = 0; i < 7; i++) {
      const start = moment(weekStart).add(i, 'day').toDate();
      const end = moment(weekStart).add(i+1, 'day').toDate();

      let dayEntries = timeEntries.filter(
        te => (te.clockIn >= start && te.clockIn <= end) ||
              (te.clockOut >= start && te.clockOut <= end));
      let correctedDayEntries: TimeEntry[] = [];

      dayEntries.forEach(entry => {
        let correctedEntry = new TimeEntry();
        correctedEntry.clockInTimestamp = moment.max(moment(entry.clockIn), moment(start)).toDate();
        correctedEntry.clockOutTimestamp = moment.min(moment(entry.clockOut), moment(end)).toDate();
        correctedDayEntries.push(correctedEntry);
      });

      let hoursSummary = correctedDayEntries.reduce((prev, current) => prev + current.duration, 0);

      if(hoursSummary > 0) {
        weekHoursSum += hoursSummary;
        let daySummary: CalendarEvent = new CalendarEvent();
        
        daySummary.id = start.toISOString();
        daySummary.start = start;
        daySummary.allDay = true; 
        daySummary.title = `${DateUtilities.formatMillisecondsAsDuration(hoursSummary)} total`;
        daySummary.duration = hoursSummary;

        summaries.push(daySummary);
      }
    }

    return [weekHoursSum, summaries];
  }

  private async splitTimeEntriesAtWeekBoundaries() {
    const start = moment(this.weekStart).toDate();
    const end = moment(this.weekStart).add(1, 'week').toDate();
    let entries = await this.timeEntrySvc.getTimeEntriesForWeek(this.weekStart);

    let earlyEntries = entries.filter(te => te.clockIn < start);
    let lateEntries = entries.filter(te => te.clockOut > end);

    await Promise.all(earlyEntries.map(te => this.splitTimeEntryAtTimestamp(te, start)));
    await Promise.all(lateEntries.map(te => this.splitTimeEntryAtTimestamp(te, end)));
  }

  private async splitTimeEntryAtTimestamp(timeEntry: TimeEntry, timestamp: Date) {
    const overrideReason = "system-adjustment";
    let copy = timeEntry.copy();
    if(timeEntry.clockIn < timestamp || timeEntry.clockOut > timestamp) {
      timeEntry.clockOutOverride = timestamp;
      timeEntry.clockOutOverrideReason = overrideReason;
      copy.clockInOverride = timestamp;
      copy.clockInOverrideReason = overrideReason;
      copy.id = null;
      await this.timeEntrySvc.Update(timeEntry);
      await this.timeEntrySvc.Add(copy);
    }
  }
}
