import { Pipe, PipeTransform } from '@angular/core';
import * as moment from "moment";
import { DateUtilities } from '../shared/date-utilities';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    var numVal = value as number;
    if(!numVal) return null;

    if(args !== 'milliseconds') { // default is in minutes, must request milliseconds handling
      numVal = numVal * 60000;
    }

    return DateUtilities.formatMillisecondsAsDuration(numVal);
  }

}
