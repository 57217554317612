export class GoogleToken {
    constructor(private accessToken: string, private timestamp: number) {}

    // TODO: build in validity checking here?

    public AccessToken(): string {
        get: {
            return this.accessToken;
        }
    }

    public Timestamp(): number {
        get: {
            return this.timestamp;
        }
    }
}
