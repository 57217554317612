import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { NgbModal, ModalDismissReasons, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute } from '@angular/router';
import { debounceTime, distinctUntilChanged, pluck } from 'rxjs/operators';

import { MealModalComponent } from './meal-modal/meal-modal.component';
import { Meal } from '../models/meal';
import { Shift } from '../models/shift';
import { Note } from '../models/note';
import { CalendarEvent } from '../models/calendar-event';
import { GapiService } from '../services/google-api.service';
import { TimeEntry } from '../models/time-entry';
import { ShiftService } from '../services/shift.service';
import { GeolocationService } from '../services/geolocation.service';
import { ClientNameComponent } from './client-name/client-name.component';
import { ClockInComponent } from './clock-in/clock-in.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClockOutComponent } from './clock-out/clock-out.component';
import { TimeEntryService } from '../services/time-entry.service';
import { ClientService } from '../services/client.service';
import { UserProfileService } from '../services/user-profile.service';
import { Client } from '../models/client';

@Component({
  selector: 'app-shift',
  templateUrl: './shift.component.html',
  styleUrls: ['./shift.component.scss']
})
export class ShiftComponent implements OnInit {
  @ViewChild('shiftForm') shiftForm;
  
  shift: Shift = new Shift();
  newNote: Note = new Note();
  currentTimeEntry: TimeEntry;
  event: CalendarEvent;
  availableClients: Client[];

  constructor(public auth: AngularFireAuth, private modalService: NgbModal, private route: ActivatedRoute, 
              private apiSvc: GapiService, private shiftSvc: ShiftService, private timeEntrySvc: TimeEntryService,
              private geoSvc: GeolocationService, private spinner: NgxSpinnerService, private clientSvc: ClientService,
              private profileSvc: UserProfileService) { }

  ngOnInit() {
    let eventId = this.route.snapshot.params.id;
    this.loadData(eventId);
  }

  async loadData(eventId) {
    this.spinner.show();    
    let user = await this.profileSvc.getCurrentUser();
    this.availableClients = await this.clientSvc.GetClientsByIds(user.clients);
    this.event = await this.apiSvc.getCalendarEvent(eventId); 
    this.shift = await this.shiftSvc.getShift(eventId, this.event.parentId);
    let shiftId: string = this.shift.shiftId;
    let mealPromise = this.shiftSvc.getMeals(shiftId);
    let notesPromise = this.shiftSvc.getNotes(shiftId);
    let timeEntriesPromise = this.timeEntrySvc.getTimeEntriesForShift(shiftId);

    if(!this.shift.clientId) {
      this.spinner.hide();
      console.log('prompt w/ modal for client name');
      this.shift.clientId = await this.editClientNameInModal();
      this.shiftSvc.updateShift(this.shift);
      this.spinner.show();
    }

    Promise.all([mealPromise, notesPromise, timeEntriesPromise]).then(results => {
      this.shift.meals = results[0];
      this.shift.notes = results[1];
      this.shift.timeEntries = results[2];

      this.subscribeToFormChanges();
      this.spinner.hide();
    });
  }

  subscribeToFormChanges() {
    this.shiftForm.valueChanges
      .pipe(
        debounceTime(2000),
        distinctUntilChanged(),
        pluck('clientName', 'energyLevel', 'appetiteLevel', 'painLevel')
      )
      .subscribe(() => {
        console.log('updating shift for changes');
        this.shiftSvc.updateShift(this.shift);
      });
  }

  clockIn() {
    this.clockInModal().then(response => {
      if(!response) return;

      this.geoSvc.getGeolocation().then(position => {
        console.log('clock in');
        if(response.override) {
          this.shift.clockIn(position, response.time, response.reason);
        } else {
          this.shift.clockIn(position);
        }
        this.shift.currentTimeEntry.uid = this.auth.auth.currentUser.uid;
        this.timeEntrySvc.Add(this.shift.currentTimeEntry);
      });
    });
  }

  clockOut() {
    this.clockOutModal().then(response => {
      if(!response) return;

      this.geoSvc.getGeolocation().then(position => {
        console.log('clock out');
        var timeEntry = this.shift.currentTimeEntry;
        if(response.override) {
          this.shift.clockOut(position, response.time, response.reason);
        } else {
          this.shift.clockOut(position);
        }
        this.timeEntrySvc.Update(timeEntry);
      });
    });
  }

  addMeal() {
    let newMeal: Meal = new Meal();
    this.editMealInModal(newMeal).then((meal) => {
      if(meal) {
        this.shiftSvc.saveMeal(this.shift.shiftId, meal)
          .then(newMeal => this.shift.meals.push(newMeal));
      }
    });
  }

  editMeal(meal: Meal) {
    this.editMealInModal(meal).then((editMeal) => {
      if(editMeal) {
        meal.meal = editMeal.meal;
        meal.food = editMeal.food;
        meal.percent = editMeal.percent;
        meal.time = new Date(editMeal.time);
        this.shiftSvc.updateMeal(meal);
      }
    });
  }

  private editMealInModal(meal: Meal): Promise<Meal> {
    const modalRef: NgbModalRef = this.modalService.open(MealModalComponent);
    let copyMeal: Meal = new Meal();
    copyMeal.meal = meal.meal;
    copyMeal.food = meal.food;
    copyMeal.percent = meal.percent;
    copyMeal.time = meal.time;
    modalRef.componentInstance.meal = copyMeal;
    
    return modalRef.result
      .then((result) => {
        return result;
      }, (reason) => {
        console.log('meal modal dismiss: ', reason);
        return null;
      });
  }

  private clockInModal(): Promise<any> {
    const modalRef: NgbModalRef = this.modalService.open(ClockInComponent);
    modalRef.componentInstance.previousClockOut = this.shift.latestClockOutTimestamp;
    modalRef.componentInstance.shiftEventStart = this.event.start;

    return modalRef.result
      .then((result) => {
        return result;
      }, (reason) => {
        console.log('clock in modal dismiss: ', reason);
        return null;
      });
  }

  private clockOutModal(): Promise<any> {
    const modalRef: NgbModalRef = this.modalService.open(ClockOutComponent);
    modalRef.componentInstance.previousClockIn = this.shift.clockInTimestamp;
    modalRef.componentInstance.shiftEventEnd = this.event.end;
    
    return modalRef.result
      .then((result) => {
        return result;
      }, (reason) => {
        console.log('clock out modal dismiss: ', reason);
        return null;
      });
  }

  private editClientNameInModal(): Promise<string> {
    const modalRef: NgbModalRef = this.modalService.open(ClientNameComponent);
    modalRef.componentInstance.clientId = null;
    
    return modalRef.result
      .then((result) => {
        return result;
      }, (reason) => {
        console.log('client name modal dismiss: ', reason);
        // if they closed it, just reprompt
        return this.editClientNameInModal();
      });
  }

  addNote() {
    console.log('add note');
    this.newNote.time = new Date();
    this.shiftSvc.saveNote(this.shift.shiftId, this.newNote).then(note => {
      this.shift.notes.push(note);
      this.newNote = new Note();
    });
  }

  editNote(note: Note) {
    console.log('edit note');
    note.isEditing = true;
  }

  saveNote(note: Note) {
    console.log('save note');
    note.time = new Date();
    this.shiftSvc.updateNote(note).then(() => {
      note.isEditing = false;
    });
  }

  onSubmit() {
    console.log('submit');
  }

  get userDisplayName() {
    return this.auth.auth.currentUser.displayName;
  }
}
