import { Component, OnInit } from '@angular/core';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { UserProfile } from 'src/app/models/user-profile';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserEditComponent } from './user-edit/user-edit.component';
import { Client } from 'src/app/models/client';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-user-admin',
  templateUrl: './user-admin.component.html',
  styleUrls: ['./user-admin.component.scss']
})
export class UserAdminComponent implements OnInit {
  profiles: UserProfile[] = [];
  clients: Client[] = [];

  constructor(private profileSvc: UserProfileService, private clientSvc: ClientService
              , private modalService: NgbModal) { }

  async ngOnInit() {
    this.profiles = await this.profileSvc.GetAll();
    this.clients = await this.clientSvc.GetAll();
  }

  async editUser(profile: UserProfile) {
    const modalRef: NgbModalRef = this.modalService.open(UserEditComponent);
    
    let copyProfile: UserProfile = profile.copy();
    modalRef.componentInstance.user = copyProfile;
    modalRef.componentInstance.clients = this.clients;

    let result = <UserProfile> await modalRef.result;
    let savedProfile = await this.profileSvc.Update(result);
    profile = savedProfile.copy(profile);
  }

}
