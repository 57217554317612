import { CanActivate, Router, ActivatedRoute, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Injectable } from '@angular/core';
// tslint:disable-next-line: import-blacklist
import { Observable, from } from 'rxjs';
import { take, map, tap } from "rxjs/operators";

@Injectable()
export class AuthGuard implements CanActivate {

	constructor(private auth: AngularFireAuth, private router: Router, private route: ActivatedRoute) { }

	canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<boolean> {
    return from(this.auth.authState)
      .pipe(take(1))
      .pipe(map(state => !!state))
      .pipe(tap(authenticated => {
        if (!authenticated) {
          this.router.navigate(['/login'], {
            queryParams: {
              return: routerState.url
            }
          });
        }
    }));
  }
}
