import { Meal } from './meal';
import { Note } from './note';
import { TimeEntry } from './time-entry';
import * as _ from 'lodash';

export class Shift {
  shiftId: string;
  eventId: string;
  parentEventId: string;
  clientId: string;
  energyLevel: number;
  appetiteLevel: number;
  painLevel: number;
  timeEntries: Array<TimeEntry> = [];
  meals: Array<Meal> = [];
  notes: Array<Note> = [];
  uid: string;
  
  get currentTimeEntry(): TimeEntry {
    if(!this.timeEntries.length) return null;
    return _.find(this.timeEntries, te => !te.clockOut);
  }

  get clockedIn(): boolean {
    return !!this.currentTimeEntry;
  }

  get clockInTimestamp(): Date {
    return this.currentTimeEntry ? this.currentTimeEntry.clockIn : null;
  }

  get latestClockOutTimestamp(): Date {
    if(!this.timeEntries.length && !_.some(this.timeEntries, te => te.clockOut)) return null;
    return _.maxBy(this.timeEntries, te => te.clockOut).clockOut;
  }

  get duration(): number {
    return Math.ceil(_.sumBy(this.timeEntries, te => te.duration)/60000.0);
  }

  clockIn(position: Position, override: Date = null, reason: string = null) {
    if(this.clockedIn) throw new Error("Cannot clock in when already clocked in");
    let te = new TimeEntry();
    te.clockInOverride = override;
    te.clockInOverrideReason = reason;
    te.clockInPosition = position;
    te.shiftId = this.shiftId;
    te.clientId = this.clientId;
    this.timeEntries.push(te);
  }

  clockOut(position: Position, override: Date = null, reason: string = null) {
    if(!this.clockedIn) throw new Error("Cannot clock out when not clocked in");
    let te = this.currentTimeEntry;
    te.clientId = this.clientId;
    te.clockOutTimestamp = new Date();
    te.clockOutOverride = override;
    te.clockOutOverrideReason = reason;
    te.clockOutPosition = position;
  }
}
