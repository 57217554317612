import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from "moment";

@Component({
  selector: 'app-shift-add',
  templateUrl: './shift-add.component.html',
  styleUrls: ['./shift-add.component.scss']
})
export class ShiftAddComponent implements OnInit {
  shiftModel: any;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    let timestamp = new Date();

    let hour = timestamp.getHours();
    let minute = timestamp.getMinutes();
    this.shiftModel = {
      date: { 
        year: timestamp.getFullYear(),
        month: timestamp.getMonth()+1,
        day: timestamp.getDate()
      },
      time: {
        hour: hour,
        minute: minute
      },
      duration: 8
    };
  }

  onSubmit() {
    let shiftDate = new Date(this.shiftModel.date.year
                            , this.shiftModel.date.month-1
                            , this.shiftModel.date.day
                            , this.shiftModel.time.hour
                            , this.shiftModel.time.minute);
    let response: any = { 
      start: shiftDate,
      end: moment(shiftDate).add(this.shiftModel.duration, 'hour').toDate()      
    };
    
    this.activeModal.close(response);
  }

}
