import { Injectable } from '@angular/core';
import { GoogleToken } from '../models/google-token';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  setGoogleAccessToken(googleAccessToken: string) {
    if(googleAccessToken === null) localStorage.setItem('googleAccessToken', '');
    
    let token: GoogleToken = new GoogleToken(googleAccessToken, Date.now());
    localStorage.setItem('googleAccessToken', JSON.stringify(token));
  }

  getGoogleAccessToken(): GoogleToken {
    let storageValue = localStorage.getItem('googleAccessToken');
    if(!storageValue) return null;


    let tokenObj: any = JSON.parse(storageValue);
    let token: GoogleToken = new GoogleToken(tokenObj.accessToken, tokenObj.timestamp);

    if(!token || !token.AccessToken() || !token.Timestamp()) return null;

    // if the token is more than 45 minutes old, get a new one
    if((Date.now() - token.Timestamp()) / 60000 > 45) return null;

    return token;
  }

  getShiftClientId(parentId: string): string {
    let storageValue = localStorage.getItem('CLIENTID_'+parentId);
    if(!storageValue) return null;
    else return storageValue;
  }

  setShiftClientId(parentId: string, clientName: string) {
    localStorage.setItem('CLIENTID_'+parentId, clientName);
  }
}
