import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TimeCalcsService } from 'src/app/services/time-calcs.service';

@Component({
  selector: 'app-clock-out',
  templateUrl: './clock-out.component.html',
  styleUrls: ['./clock-out.component.scss']
})
export class ClockOutComponent implements OnInit {
  @Input() previousClockIn: Date;
  @Input() shiftEventEnd: Date;
  override: boolean = false;
  clockTime: any = { hour: 0, minute: 0 };
  overrideReason: string;

  constructor(public activeModal: NgbActiveModal, private timeSvc: TimeCalcsService) {
  }

  ngOnInit() {
    let date = new Date();
    this.clockTime.hour = date.getHours();
    this.clockTime.minute = date.getMinutes();
  }

  onSubmit() {
    let response: any = { override: this.override };
    if(response.override) {
      if(this.previousClockIn) {
        response.time = this.timeSvc.getNextTime(this.previousClockIn, this.clockTime.hour, this.clockTime.minute);
      } else {
        response.time = this.timeSvc.getClosestTime(this.shiftEventEnd, this.clockTime.hour, this.clockTime.minute);
      }
      response.reason = this.overrideReason;
    }
    
    this.activeModal.close(response);
  }

}