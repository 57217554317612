import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientService } from 'src/app/services/client.service';
import { Client } from 'src/app/models/client';
import { UserProfileService } from 'src/app/services/user-profile.service';

@Component({
  selector: 'app-client-name',
  templateUrl: './client-name.component.html',
  styleUrls: ['./client-name.component.scss']
})
export class ClientNameComponent implements OnInit {
  @Input() clientId: string;
  availableClients: Client[];

  constructor(public activeModal: NgbActiveModal, private clientSvc: ClientService, private profileSvc: UserProfileService) { }

  async ngOnInit() {
    let user = await this.profileSvc.getCurrentUser();
    this.availableClients = await this.clientSvc.GetClientsByIds(user.clients);

    console.log(this.availableClients);
  }

  onSubmit() {
    console.log(this.clientId);
    this.activeModal.close(this.clientId);
  }
}
