import { CanActivate, Router, ActivatedRoute, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Injectable } from '@angular/core';
// tslint:disable-next-line: import-blacklist
import { Observable, from } from 'rxjs';
import { take, map, tap } from "rxjs/operators";
import { UserProfileService } from './user-profile.service';

@Injectable()
export class AdminService implements CanActivate {

  constructor(private auth: AngularFireAuth, private router: Router, private route: ActivatedRoute, private profileSvc: UserProfileService) { }

	canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<boolean> {
    return this.profileSvc.isCurrentUserAdmin()
      .pipe(tap(isAdmin => {
        if (!isAdmin) {
          throw new Error('Admin access was not granted');
        }
    }));
  }
}