import { Directive, forwardRef, Attribute, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import * as _ from "lodash";

@Directive({
  selector: '[NotFuture]',
  providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => NotFutureDirective), multi: true }]
})
export class NotFutureDirective implements Validator {
  constructor() {}

  @Input('NotFuture') compareDate: Date;
  
  validate(c: AbstractControl): { [key: string]: any } {
    let futureInvalid = false;

    if(c.value && _.isFinite(c.value.hour) && _.isFinite(c.value.minute)) { 
      let editVal = new Date(this.compareDate);
      editVal.setHours(c.value.hour, c.value.minute);

      futureInvalid = Date.now() - editVal.valueOf() < 0;
    }
     
    return futureInvalid ? { futureInvalid } : null;
  }
}