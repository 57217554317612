export class Meal {
  id: string;
  shiftId: string;
  meal: string;
  time: Date;
  food: string;
  percent: number;
  uid: string;

  constructor() {
    this.time = new Date();
  }
}
