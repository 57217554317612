import { Component, OnInit } from '@angular/core';
import { Client } from 'src/app/models/client';
import { ClientService } from 'src/app/services/client.service';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientEditComponent } from './client-edit/client-edit.component';

@Component({
  selector: 'app-client-admin',
  templateUrl: './client-admin.component.html',
  styleUrls: ['./client-admin.component.scss']
})
export class ClientAdminComponent implements OnInit {
  clients: Client[] = [];

  constructor(private clientSvc: ClientService, private modalService: NgbModal) { }

  async ngOnInit() {
    this.clients = await this.clientSvc.GetAll();
  }

  async addClient() {
    const modalRef: NgbModalRef = this.modalService.open(ClientEditComponent);

    let result = await modalRef.result;
    console.log('add modal result: ', result);
    let newClient = await this.clientSvc.Add(result);
    this.clients.push(newClient);
  }

  async editClient(client: Client) {
    const modalRef: NgbModalRef = this.modalService.open(ClientEditComponent);
    
    let copyClient: Client = client.copy();
    modalRef.componentInstance.client = copyClient;

    let result = <Client> await modalRef.result;
    let savedClient = await this.clientSvc.Update(result);
    client = savedClient.copy(client);
  }
}
