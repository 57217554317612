import { Injectable } from '@angular/core';
import { Client } from '../models/client';
import { AngularFirestore } from '@angular/fire/firestore';
import { BaseFirestoreService } from './base-firestore.service';

@Injectable({
  providedIn: 'root'
})
export class ClientService extends BaseFirestoreService<Client> {
  constructor(protected firestore: AngularFirestore) {
    super(firestore);
  }

  collection: string = "client";

  async getItemFromFirestoreData(data: any): Promise<Client> {
    let client: Client = new Client();
    
    client.clientName = data.clientName;
    client.address = data.address;
    client.description = data.description;
    client.phone = data.phone;
    
    return client;
  }
  
  async GetClientsByIds(clientIds: string[]): Promise<Client[]> {
    let allClients = await this.GetAll();
    return allClients.filter(c => clientIds.includes(c.id));
  }
}
