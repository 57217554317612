import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './admin/login/login.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './services/auth.service';
import { ShiftCalendarComponent } from './shift-calendar/shift-calendar.component';
import { ShiftComponent } from './shift/shift.component';
import { ApiGuardService } from './services/api-guard.service';
import { GeolocationService } from './services/geolocation.service';
import { GeolocationComponent } from './admin/geolocation/geolocation.component';
import { UserAdminComponent } from './admin/user-admin/user-admin.component';
import { AdminService } from './services/admin.service';
import { ClientAdminComponent } from './admin/client-admin/client-admin.component';
import { TimesheetsComponent } from './timesheets/timesheets.component';

export const routes: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent },
    { path: 'timesheets', component: TimesheetsComponent, canActivate: [AuthGuard] },
    { path: 'shifts', component: ShiftCalendarComponent, canActivate: [AuthGuard, ApiGuardService] },
    { path: 'shifts/:id', component: ShiftComponent, canActivate: [AuthGuard, ApiGuardService, GeolocationService] },
    { path: 'users', component: UserAdminComponent, canActivate: [AuthGuard, AdminService] },
    { path: 'clients', component: ClientAdminComponent, canActivate: [AuthGuard, AdminService] },
    { path: 'login', component: LoginComponent },
    { path: 'geolocation', component: GeolocationComponent }
 ];
 
 @NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
 })
 export class AppRoutingModule {
 }
 