import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss']
})
export class DataComponent implements OnInit {

  constructor(private dataService: DataService ) { }

  ngOnInit() {
  }

  addData() {
    this.dataService.createRecord().then(val => {
      console.log(val.id);
      // alert(val)
    });
  }

}
