import { BaseModel } from './base-model';

export class Client extends BaseModel {
    clientName: string;
    address: string;
    description: string;
    phone: string;

    copy(target: Client = new Client()): Client {
      target.id = this.id;
      target.clientName = this.clientName;
      target.address = this.address;
      target.description = this.description;
      target.phone = this.phone;

      return target;
    }
}
