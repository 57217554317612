import { Component, OnInit, Input } from '@angular/core';
import { FormControl, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";
import { Meal } from 'src/app/models/meal';

@Component({
  selector: 'app-meal-modal',
  templateUrl: './meal-modal.component.html',
  styleUrls: ['./meal-modal.component.scss']
})
export class MealModalComponent implements OnInit {
  @Input() meal: Meal;
  mealTypes: Array<string> = ['BRK','LUN','DIN','OTH'];
  mealTime: any = { hour: 0, minute: 0 };
  isNew: boolean = true;
  editTime: boolean = false;
  
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    // TODO: write these back on close success
    this.mealTime.hour = this.meal.time.getHours();
    this.mealTime.minute = this.meal.time.getMinutes();
    this.isNew = !(this.meal && this.meal.meal && this.meal.food && this.meal.percent && _.isFinite(this.meal.percent));
  }

  onSubmit() {
    this.meal.time.setHours(this.mealTime.hour, this.mealTime.minute);
    this.activeModal.close(this.meal);
  }

  get mealPercent(): number {
    if(!this.meal || !this.meal.percent) return 0;
    return Math.round(this.meal.percent * 10);
  }

  set mealPercent(value: number) {
    this.meal.percent = value / 10;
  }
}