import * as moment from "moment";
import { BaseModel } from './base-model';

export class TimeEntry extends BaseModel {
  shiftId: string;
  clientId: string;
  clockInTimestamp: Date = new Date();
  clockInPosition: Position = null;
  clockInOverride: Date = null;
  clockInOverrideReason: string = null;
  clockOutTimestamp: Date = null;
  clockOutPosition: Position = null;
  clockOutOverride: Date = null;
  clockOutOverrideReason: string = null;
  // updateTimestamp: Date;
  uid: string;
  locked: boolean = false;

  get clockIn(): Date {
    return this.clockInOverride || this.clockInTimestamp;
  };

  get clockOut(): Date {
    return this.clockOutOverride || this.clockOutTimestamp;
  };

  get duration(): number {
    return moment(this.clockOut || new Date()).diff(this.clockIn);
  }

  copy(target: TimeEntry = new TimeEntry()): TimeEntry {
    target.id = this.id;
    target.uid = this.uid;
    target.locked = this.locked || false;
    target.shiftId = this.shiftId;
    target.clientId = this.clientId;
    target.clockInTimestamp = this.clockInTimestamp;
    target.clockInPosition = this.clockInPosition;
    target.clockInOverride = this.clockInOverride;
    target.clockInOverrideReason = this.clockInOverrideReason;

    target.clockOutTimestamp = this.clockOutTimestamp;
    target.clockOutPosition = this.clockOutPosition;
    target.clockOutOverride = this.clockOutOverride;
    target.clockOutOverrideReason = this.clockOutOverrideReason;

    return target;
  }
}