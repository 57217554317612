import { firestore } from 'firebase';
import * as moment from "moment";

export class DateUtilities {

  static getDateFromNullableTimestamp(timestamp: any): Date {
    return timestamp && timestamp instanceof firestore.Timestamp ? timestamp.toDate() : null;
  }

  static getTimestampFromNullableDate(date: Date): firestore.Timestamp {
    return date ? firestore.Timestamp.fromDate(date) : null;
  }

  private static zeroPad(value: number): string {
    return value.toString().padStart(2, '0');
  }

  static formatMillisecondsAsDuration(value: number): string {
    let duration = moment.duration(value);
    return `${this.zeroPad(duration.hours())}:${this.zeroPad(duration.minutes())}`;
  }
}
  