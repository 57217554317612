import { Component, OnInit, AfterViewInit, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router, ActivatedRoute } from '@angular/router';
import * as firebase from 'firebase/app';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { GoogleAuthService } from 'ng-gapi';
import { NgxSpinnerService } from 'ngx-spinner';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserProfile } from 'src/app/models/user-profile';
import { UserProfileService } from 'src/app/services/user-profile.service';

@Component({
   selector: 'app-login',
   templateUrl: './login.component.html',
   styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
   return = '';
   error: any;
   user: gapi.auth2.GoogleUser;

   constructor(public afAuth: AngularFireAuth, private router: Router, private route: ActivatedRoute, 
      private storageSvc: LocalStorageService, private googleAuth: GoogleAuthService, private ngZone: NgZone,
      private spinner: NgxSpinnerService, private firestore: AngularFirestore, private profileSvc: UserProfileService) {
   }

   ngOnInit() {
      // Get the query params
      this.route.queryParams
         .subscribe(params => this.return = params['return'] || '/home');
   }

   ngAfterViewInit() {
   }

   loginGoogle() {
      this.spinner.show();
      this.googleAuth.getAuth().subscribe(
         (auth) => auth.signIn()
            .then(res => this.signInSuccessHandler(res), err => this.signInFailureHandler(err))
            .then(() => this.ngZone.run(() => this.spinner.hide()))
         );
   }

   private async signInSuccessHandler(res: gapi.auth2.GoogleUser) {
      this.user = res;
      this.storageSvc.setGoogleAccessToken(res.getAuthResponse().access_token);

      var idToken = this.user.getAuthResponse().id_token;
      var creds = firebase.auth.GoogleAuthProvider.credential(idToken);

      let user = await this.afAuth.auth.signInWithCredential(creds);
      var googleProfile = this.user.getBasicProfile();
      console.log(googleProfile);
      console.log(user.user);
      let userProfile = new UserProfile();
      userProfile.id = user.user.uid;
      userProfile.uid = user.user.uid;
      userProfile.providerId = googleProfile.getId();
      userProfile.email = googleProfile.getEmail();
      userProfile.givenName = googleProfile.getGivenName();
      userProfile.familyName = googleProfile.getFamilyName();
      userProfile.name = googleProfile.getName();
      userProfile.imageUrl = googleProfile.getImageUrl() || null;
      userProfile.lastLogin = new Date();

      userProfile = await this.profileSvc.createOrUpdateUserProfile(userProfile);
      console.log(userProfile);

      this.ngZone.run(() => this.router.navigateByUrl(this.return));
   }

   private signInFailureHandler(error: any) {
      let errorMessage = "Error: "+JSON.stringify(error.error || error);
      this.error = errorMessage;
      console.error(errorMessage);
   }
}

