import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { AppService } from './app.service';
import { GoogleToken } from '../models/google-token';
import { CalendarEvent } from '../models/calendar-event';
import { GoogleApiService } from 'ng-gapi';

@Injectable({
  providedIn: 'root'
})
export class GapiService {
  constructor(private storageService: LocalStorageService, private appSvc: AppService, private gapiSvc: GoogleApiService) { }

  private async initClient(): Promise<any> {
    if(gapi.client) return;

    await new Promise((res, rej) => {
      gapi.load('client', () => res());
    });
  }

  getCurrentGoogleAccessToken(): Promise<GoogleToken> {
    var googleToken = this.storageService.getGoogleAccessToken();

    return Promise.resolve(googleToken);
  }

  private async getInitializedClient(): Promise<any> {
    await this.initClient();
    var token = await this.getCurrentGoogleAccessToken();
    gapi.client.setToken({access_token:token.AccessToken()});
  }

  async getCalendarEvents(params: any): Promise<Array<any>> {
    await this.getInitializedClient();
    let response = await gapi.client.request({
      'path': `https://www.googleapis.com/calendar/v3/calendars/primary/events`,
      'params': {
        singleEvents: true,
        timeMax: params.endStr,
        timeMin: params.startStr
      }
    });
    
    var items = response.result.items as Array<any>;
    var eventList = items.map(entry => new CalendarEvent().copyGCalEvent(entry));

    return eventList;
  }

  async getCalendarEvent(eventId: string, attempt?: number): Promise<CalendarEvent> {
    await this.getInitializedClient();
    let response = await gapi.client.request({
          'path': `https://www.googleapis.com/calendar/v3/calendars/primary/events/${eventId}`,
          'params': {
            singleEvents: true
          }
        });
    return new CalendarEvent().copyGCalEvent(response.result);
  }

  async addCalendarEvent(start: Date, end: Date) : Promise<CalendarEvent> {
    await this.getInitializedClient();
    let event = {
      start: { dateTime: start.toISOString() },
      end: { dateTime: end.toISOString() }
    };
    let response = await gapi.client.request({
      body: event,
      path: 'https://www.googleapis.com/calendar/v3/calendars/primary/events',
      method: 'POST'
    });
    return new CalendarEvent().copyGCalEvent(response.result);
  }
}
