import { AngularFirestore } from '@angular/fire/firestore';
import { BaseModel } from '../models/base-model';
import { firestore } from 'firebase/app';

type CollectionQuery = (collectionRef: firestore.CollectionReference) => firestore.Query;

// todo: use converters for custom objects https://firebase.google.com/docs/firestore/query-data/get-data#custom_objects
export abstract class BaseFirestoreService<T extends BaseModel> {
  abstract collection: string;

  constructor(protected firestore: AngularFirestore) { }

  abstract async getItemFromFirestoreData(docData: any): Promise<T>;

  mapItemToFirestoreData(item: any): any {
    return item;
  }

  private prepareItemForPersist(item: T): any {
    return this.mapItemToFirestoreData({...item});
  }

  private async getItemsFromSnapshot(snapshot: firestore.QuerySnapshot) {
    let items = await Promise.all(snapshot.docs.map<Promise<T>>(async (doc) => {
      let item: T = await this.getItemFromFirestoreData(doc.data());
      item.id = doc.id;
      return item;
    }));

    return items;
  }


  async GetAll(): Promise<T[]> {
    let querySnapshot = await this.firestore.collection(this.collection).get().toPromise();

    return this.getItemsFromSnapshot(querySnapshot);
  }

  async Query(query: CollectionQuery): Promise<T[]> {    
    let collectionRef: firestore.CollectionReference = this.firestore.collection(this.collection).ref;
    let querySnapshot = await query(collectionRef).get();

    return this.getItemsFromSnapshot(querySnapshot);
  }

  async Get(id: string): Promise<T> {
    let doc = await this.firestore.collection(this.collection).doc(id).get().toPromise();
    let dbItem: T = null;

    if(doc.exists) {
      dbItem = await this.getItemFromFirestoreData(doc.data());
      dbItem.id = doc.id;
    }

    return dbItem;
  }

  async Add(item: T): Promise<T> {
    let strippedItem: any = this.prepareItemForPersist(item);
    let doc: firestore.DocumentSnapshot;

    if(item.id) {
      let docSet = await this.firestore.collection(this.collection).doc(item.id);
      docSet.set(strippedItem);
      doc = await docSet.get().toPromise();
    } else {
      let docAdd = await this.firestore.collection(this.collection).add(strippedItem);
      doc = await docAdd.get();
    }

    let dbItem: T = await this.getItemFromFirestoreData(doc.data());
    item.id = dbItem.id = doc.id;

    return dbItem;
  }

  async Update(item: T): Promise<T> {
    let strippedItem: any = this.prepareItemForPersist(item);

    await this.firestore.collection(this.collection).doc(item.id).update(strippedItem);
    let doc = await this.firestore.collection(this.collection).doc(item.id).get().toPromise();

    let dbItem: T = await this.getItemFromFirestoreData(doc.data());
    dbItem.id = doc.id;

    return dbItem;
  }

  async Delete(item: T): Promise<void> {
    throw new Error("Not implemented");
  }
}
